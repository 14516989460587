import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Card from "../UI/Card"
import LinkCard from "../UI/LinkCard"
import KevinAvatar from "./Avatars/KevinAvatar"
import PatriciaAvatar from "./Avatars/PatriciaAvatar"
import HeadinBlock from "../UI/HeadingBlock"
import CtaBanner from "../Common/CtaBanner"

About.propTypes = {
	className: PropTypes.string.isRequired,
}

function About({ className }) {

	return (
		<div className={className}>
			<HeadinBlock>
				<h1>About us</h1>
			</HeadinBlock>
			<main>
				<Card as="article">
					<header>
						<h2>Kevin Nicholls</h2>
						
						<div className="header-info">
							<div className="contact-info">
								<LinkCard to="tel:00447767306983" icon="phone">0044 7767 306983</LinkCard>
								<LinkCard to="mailto:kevin@zenpharm.co.uk" icon="mail">kevin@zenpharm.co.uk</LinkCard>
								<LinkCard to="https://linkedin.com/in/kevin-nicholls-6139364" icon="linkedin">Kevin's Linkedin</LinkCard>
							</div>

							<KevinAvatar />
						</div>
					</header>
					
					<main>
						<div className="skillset-list">
							<h3>Specialties:</h3>
							<ul>
								<li>QA Consultancy</li>
								<li>Technical</li>
								<li>Auditing</li>
								<li>Licenses & Regulatory</li>
							</ul>
						</div>

						<div className="bio">
							<p>Kevin has worked within the Pharmaceutical Industry for over 30 years in a variety of roles, including owner of a pharmaceutical business and directorships responsible for a wide range of activities: Quality, Technical, Regulatory, Research & Development & Pharmacovigilance, with working knowledge of all areas.</p>

							<p>He has extensive experience in the set up of numerous UK, EU and third country sites including pre-approval inspections to regulatory authority approval, remediation of sites facing regulatory action and providing lead and direction in interim Head of Quality roles.</p>

							<p>Kevin has worked with a large number of products, dosage forms, CMO’s and can provide product / site transfer project management and troubleshooting of production / testing issues arising to ensure continued supply.</p>

							<p>Known for his breadth and depth of knowledge of all operations and what happens in ‘real life’ pharmaceutical supply, Kevin can provide strong interim leadership of Technical & Quality functions and Technical support for new or existing site projects to secure a supply base of safe, compliant and cost-effective medicines.</p>
						</div>
					</main>
				</Card>

				<Card as="article">
					<header>
						<h2>Patricia Nicholls</h2>				
						
						<div className="header-info">
							<div className="contact-info">
								<LinkCard to="tel:00447971539589" icon="phone">0044 7971 539589</LinkCard>
								<LinkCard to="mailto:patricia@zenpharm.co.uk" icon="mail">patricia@zenpharm.co.uk</LinkCard>
								<LinkCard to="https://linkedin.com/in/patricia-nicholls-36616690" icon="linkedin">Patricia's Linkedin</LinkCard>
							</div>

							<PatriciaAvatar />
						</div>

					</header>

					<main>
						<div className="skillset-list">
							<h3>Specialties:</h3>
							<ul>
								<li>QP & RP Services</li>
								<li>QA Consultancy</li>
								<li>Auditing</li>
								<li>Training</li>
							</ul>
						</div>

						<div className="bio">
							<p>Patricia has been a Qualified Person for over 20 years, including 5 years as a QP Assessor for the Royal Society of Biology and has held many roles, including Director of Quality providing strategic lead to businesses and also hands on support as a QP. </p>
							<p>She has worked as a consultant helping many clients either obtain site approvals or improve their quality systems to ensure safe, compliant and effective products are delivered to patients efficiently.  </p>
							<p>Patricia’s career includes working within CMO’s so has hands on knowledge and experience of operations, which has proved invaluable in resolving issues affecting the supply of products. </p>
							<p>In addition, Patricia has also spent a significant proportion of her career responsible for complex supply chains for import into the UK and understands well the challenges this presents and how these can be overcome by the right quality systems and developing effective contractor relationships.</p>
							<p>Patricia is known for her drive to improve right first time and compliance of activities by designing effective quality systems, tackling problems and ensuring long term fixes to secure the future of a business.</p>
						</div>
					</main>
				</Card>
			</main>

			<CtaBanner />
		</div>
	)
}
export default styled(About)`${({ theme }) => css`
	
	h2 {
		margin-top: 0;
	}
	
	${Card} {
	
		.contact-info {
			display: flex;
			flex-direction: column;
			margin-bottom: 16px;

			> ${LinkCard} {
				font-size: 16px;
				margin-bottom: 8px;
			}
		}

		${PatriciaAvatar}, ${KevinAvatar} {
			flex-basis: 100%;
		}

		.skillset-list {
			margin-top: 24px;
			margin-bottom: 32px;

			ul {
				list-style: square;
				padding-left: 24px;
				
				> li {
					margin: 8px 0;
				}
			}
		}
	}

	@media (min-width: ${theme.bp.about}) {
		.header-info {
			display: flex;

			.contact-info {
				flex-basis: 50%;
				max-width: 320px;
				margin-right: 32px;	

				> ${LinkCard} {
					padding: 24px;
					margin-bottom: 16px;
				}
			}
			
			${PatriciaAvatar}, ${KevinAvatar} {
				flex-basis: 50%;
				max-width: 320px;
				height: auto;
			}
		}
	}

	@media (min-width: ${theme.bp.md}) {
		
		> main {
			margin: 0 auto;
			display: flex;
			flex-basis: calc(50% - 16px);
			max-width: 1400px;
			
			${Card} {
				margin: 0 16px;
				&:first-of-type {
					margin-right: 16px;
				}
				&:last-of-type {
					margin-left: 16px;
				}
			}
		}
		
		main ${Card} {

			.header-info {
				display: block;

				.contact-info {
					margin-right: 0;
					max-width: 100%;
				}

				${PatriciaAvatar}, ${KevinAvatar} {
					max-width: 100%;
					height: auto;
				}

			}

			.skillset-list {
				flex-basis: 100%;
				ul {
					${'' /* display: flex;
					flex-wrap: wrap; */}
					
					> li {
						font-size: 20px;
						${'' /* flex-basis: 50%;
						margin-bottom: 8px; */}
					}
				}
			}
		}
	}



	@media (min-width: ${theme.bp.lg}) {
	
		main ${Card} {
			padding: 48px;
			margin: 0px 64px;

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}
		
			.header-info {
				display: flex;

				.contact-info {
					flex-basis: 50%;
					max-width: 320px;
					margin-right: 32px;	

					> ${LinkCard} {
						padding: 24px;
						margin-bottom: 16px;
					}
				}
				
				${PatriciaAvatar}, ${KevinAvatar} {
					flex-basis: 50%;
					max-width: 320px;
					height: auto;
				}
			}
			
			.skillset-list {
				flex-basis: 100%;
				ul {
					display: flex;
					flex-wrap: wrap;
					
					> li {
						font-size: 20px;
						flex-basis: 50%;
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	
`}`